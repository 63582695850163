@import url('./assets/css/index.css');

#root {
  background-image: url('./assets/images/main-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  background-color: #E9EDF2 !important;
}

.primary-button {
  color: #fff !important;
  padding: 10px 20px !important;
  border-radius: 9999px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: linear-gradient(101.95deg, rgb(29, 92, 255) 5.85%, rgb(0, 169, 255) 95.35%);
}

.header {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background: transparent !important;
}

.header_container {
  background: transparent !important;
  width: 100%;
  justify-content: space-between;
}

.header_container img {
  height: 52px !important;
  margin: 30px 0;
}

.header_container button {
  padding: 10px 20px !important;
  border-radius: 9999px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: linear-gradient(101.95deg, rgb(29, 92, 255) 5.85%, rgb(0, 169, 255) 95.35%);
}

.hero-text {
  display: grid;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
} 
.hero-text p {
  display: flex;
  flex-direction: column; 
  font-size: 60px;
  line-height: 3.9rem;
  padding: 25px 15px;
  font-weight: 700;
}

.hero-text p.hero-text--small {
  color: #818181;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 400;
}

.subscribe_info {
  background-image: url('./assets/images/subscribe-bg.png'), linear-gradient(101.95deg, rgb(29, 92, 255) 5.85%, rgb(0, 169, 255) 95.35%) !important;
  background-repeat: no-repeat;
  background-position: right;
  padding: 37px 20px !important;
}
 
.subscribe_content p:first-child {
  font-size: 1.75rem;
  margin-bottom: 0.35em;
  font-weight: 900; 

}

.subscribe_content p:last-child { 
  font-size: 1.1em;
  color: #fff;
  line-height: 1.3em; 
}

.subscribe_searchbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 10px 0 50px;
}

.subscribe_searchbox {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.subscribe_searchbox input {
  position: absolute;
  left: 0;
  height: 60px;
  width: 100%;
  outline: none;
  border-radius: 30px;
  border: none;
  padding: 0 120px 0 65px;
  font-size: 1rem;
}

.subscribe_searchbox svg {
  fill: #0F91FF;
  z-index: 1;
  margin-left: 20px;
  font-size: 30px;
}

.subscribe_searchbox button {
  position: absolute;
  right: 20px;
  height: 35px;
  width: 90px;
}

.info_icon {
  margin-left: 12px;
  height: 2em !important;
  width: 2em !important;
}

.info_card {
  border-radius: 20px !important;
  padding: 40px 20px;
  background: rgb(48, 49, 52);
  background-image: linear-gradient(173deg, rgba(48, 49, 52, 1) 0%, rgba(32, 33, 36, 1) 100%);
}

.info_card h2 {
  font-weight: bold;
}

.info_card p {
  font-size: 1.1em;
  color: #fff;
  line-height: 1.3em;
}

.info_section {
  width: 100%;
}

.slide-buttons-wrapper {
  display: flex;
  justify-content: center;
}

.slide-buttons-wrapper .slide-buttons {
  min-width: 120px;
  margin: 0 5px;
  background: #181818;
  color: white;
}

.slide-buttons-wrapper .slide-buttons.slide-buttons--active {
  color: #181818;
  background-color: white;
}

.slide-figure-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.slide-figure-wrapper img.growth-figure {
  width: 110%;
}

.slide-slider {
  position: absolute;
  top: 5px;
 left: 45% !important;  
  height: 100%;
  left: 9%;
}



@keyframes SlidingAnimation {
  0% {
    left: 9%
  }

  100% {
    left: 82%
  }
}

.slide-slider--slide-right {
  animation-name: SlidingAnimation;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


.slide-figure {
  position: absolute;
  left: 0;
}

.slide-title {
  display: flex;
  flex-direction: column;
  padding: 25px 5px;
  margin: 0 20%;
  text-align: center;
}

.slide-title h2 {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 900;
  margin: 10px 0;
}

.slide-title p {
  margin: 10px;
}

.place_section {
  padding: 100px 0;
}

.place_title {
  display: flex;
  flex-direction: column;
  padding: 25px 5px;
  margin: 0 20%;
  text-align: center;
}

.place_title h2 {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 900;
  margin: 10px 0;
}

.place_title p {
  margin: 10px;
}


.place_content {
  display: flex;
  margin: 20px 0;
}

.place_content .herocard {
  margin: 0 10px;
}

.place_content .herocard:nth-child(even) {
  bottom: -50px;
  position: relative;
}



.realmoney_content-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-top: 30px;
}

.realmoney_content>img {
  width: 100%;
}

.realmoney_content {
  background-color: #353639;
  border-radius: 20px;
}

.realmoney_text {
  padding: 35px;
}

.realmoney_text h3 {
  font-size: 24px;
  font-weight: 900;
}

.realmoney_text p {
  font-size: 18px;
  margin-top: 15px;
}


.realmoney_section {
  padding: 100px 0;
}

.realmoney_title {
  display: flex;
  flex-direction: column;
  padding: 25px 5px;
  margin: 0 20%;
  text-align: center;
}

.realmoney_title h2 {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 900;
  margin: 10px 0;
}

.award-card:first-child {
  margin-left: -23%;
}

.award-card {
  min-width: 900px;
  margin-right: 10px;
  border-radius: 27px !important;
  padding: 15px 40px !important;
  background: linear-gradient(45deg, #27292b, #303943) !important;
}

.award-card-orange:first-child {
  margin-left: -10%;
}

.award-card-orange {
  min-width: 900px;
  margin-right: 10px;
  border-radius: 27px !important;
  padding: 15px 40px !important;
  background: linear-gradient(45deg, #27292b, #3f3c2f) !important;
}

.award-card h2 {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 900;
  margin: 10px 0;
}

.award-card-orange h2 {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 900;
  margin: 10px 0;
}

.award-content {
  padding: 40px;
}

.realmoney_title p {
  margin: 10px;
}

.sectionA h3 {
  line-height: 1.2em;
}

.sectionB h3 {
  line-height: 1.2em;
}

.sectionA img {
  width: 100% !important;
}

.sectionB img {
  width: 117% !important;
  border-bottom-right-radius: 58px;
  margin-left: -42px;
}


.award_content-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 100vw;
  margin-top: 25px;
  overflow-x: hidden;
}

.award_section {
  height: 900px;
  /* width: 100vw; */
  overflow-x: hidden;
}

.award_contents {
  display: flex;
  width: 100vw;
}

.award_contents-l2,
.award_contents-l1 {
  width: 100vw;
  display: flex;
  align-items: center;
}

.award_contents-l2 .award_content {
  padding: 10px;
}

.award_contents-l2 .award_content:first-child {
  padding-left: 0;
}

.award_contents-l2 .award_content:last-child {
  padding-right: 0;
}

.award_contents-l2 .award_content,
.award_contents-l2 .award_content img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.award_contents-l1 .award_content {
  width: 60%;
}

.award_contents-l1 .award_content:first-child {
  width: 20%;
}

.award_contents-l1 .award_content:last-child {
  width: 20%;
}

.award_contents-l1 .award_content img {
  width: 100%;
}


.award_title {
  display: flex;
  flex-direction: column;
  padding: 25px 5px;
  margin: 0 20%;
  text-align: center;
}

.award_title h2 {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 900;
  margin: 10px 0;
}

.award_title p {
  margin: 10px;
}

.subscribe_footer_info {
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  padding: 50px !important;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-position: right;
  margin: 0 auto !important;
}

.subscribe_footer_section {
  background-image: url('./assets/images/subscribe-bg.png'), linear-gradient(101.95deg, rgb(29, 92, 255) 5.85%, rgb(0, 169, 255) 95.35%);
  padding: 0px !important;
}

.footer_info {
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  padding: 16px !important;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-position: right;
  margin: 0 auto !important;
}

hr { display: block;
  height: 2px !important;
  border: 0;
  border-top:3px solid #acafb2;
  margin: 1em 0;
  padding: 0; }

.footer_section {
  padding: 0px !important;
}

.privacy_heading{
  background-color: #297bff;
    padding: 29px;
    color: #fff;
    text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .place_content {
    display: block;
  }

  .info_section>div:first-child {
    margin-left: 16px !important;
  }

  .info_section {
    padding: 20px;
  }


}


@media only screen and (max-width: 768px) {


  .place_content {
    display: block;
  }

  .slide-slider img {
    height: 38% !important;
  }

  .info_section>div:first-child {
    margin-left: 16px !important;
  }

  .info_section {
    padding: 20px;
  }


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
 

  .place_content {
    display: block;
  }

  .slide-slider img {
    height: 54% !important;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .subscribe_footer_info .subscribe_content p {
    line-height: 1.3em !important;
  }
 
  .place_content {
    display: block;
  }

  .slide-slider img {
    height: 68% !important;
  }

  .subscribe_section {
    padding: 0px !important;
  }

  .info_section {
    padding: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .place_content {
    display: flex;
  }

  .slide-slider img {
    height: 95% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .place_content {
    display: flex;
  }

  .slide-slider img {
    height: 98% !important;
  }
}