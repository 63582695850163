@font-face {
  font-family: "Formular";
  src: url("../fonts/Brownfox\ -\ Formular.otf");
}

@font-face {
  font-family: "Formular Light";
  src: url("../fonts/Brownfox\ -\ Formular\ Light.otf");
}

@font-face {
  font-family: "Formular Medium";
  src: url("../fonts/Brownfox\ -\ Formular\ Medium.otf");
}

@font-face {
  font-family: "Formular Bold";
  src: url("../fonts/Brownfox\ -\ Formular\ Bold.otf");
}
