 
.footer_content {
    display: flex;
    color:#818181 !important;
    flex-direction: column;
}
.footer_content-item {
    line-height: 1.6;
    margin: 11px 0px !important;
    font-weight: 600;
    color:#818181 !important;
    font-size: 14px;
}

.footer_content-item:first-child {
    line-height: 1.8;
    font-size: 15px;
    text-transform: uppercase;
    color: #818181;
}
  
.footer_terms-section {
    display: flex;
    color:#818181 !important;
    justify-content:flex-end;
}

.footer_terms-section a { 
    color:#818181 !important; 
    text-decoration: none;
}

.footer_terms-section p {
    margin: 0 2px;
}